import React from 'react';
import { TextInput, TextInputProps } from '@mantine/core';
import { globalStyles } from '@/utils/globalStyles';

type RTextInputRef = React.Ref<HTMLInputElement>;

export const RTextInput = React.forwardRef(
  (props: Omit<TextInputProps, 'classNames'>, ref: RTextInputRef) => {
    return <TextInput {...props} ref={ref} classNames={{ ...globalStyles.field }} />;
  },
);

RTextInput.displayName = 'RTextInput';
