import axios from 'axios';
import { captureException } from '@sentry/react';
import { getStorage } from '@/utils/storage';

const baseURL = import.meta.env.VITE_APP_API_HOST;

export const api = axios.create({ baseURL });

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { status } = error.response;

    if (status !== 401 || status !== 404) {
      captureException({
        url: error.response.config.url,
        method: error?.config?.method,
        body: error.config.data,
        err: error.response.data,
        errFormat: JSON.stringify(error.response.data),
      });
    }

    const storage = getStorage();

    if (window.location.href !== '/' && status === 401) {
      storage.remove.accessToken();

      window.location.href = `/auth/signout`;
    }
    return Promise.reject(error);
  },
);
