import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// @ts-ignore
import routes from '~react-pages';
import { DashboardLayout } from '@/layouts/dashboard/Layout';
import { setAuthorizationHeader } from '@/utils/authorization';

setAuthorizationHeader();
const queryClient = new QueryClient();

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <MantineProvider
          theme={{
            fontFamily: 'Poppins, sans-serif',
            headings: { fontFamily: 'Poppins, sans-serif' },
          }}
        >
          <DashboardLayout>
            <Suspense fallback={<p>Loading...</p>}>{useRoutes(routes)}</Suspense>
          </DashboardLayout>
        </MantineProvider>
      </QueryClientProvider>
      <ToastContainer autoClose={2000} />
    </>
  );
}

export default App;
