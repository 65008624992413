import React from 'react';

export function useTogglePassword() {
  const [type, setType] = React.useState('password');

  const togglePassword = React.useCallback(() => {
    const changed = type === 'password' ? 'text' : 'password';
    setType(changed);
  }, [type]);

  return { type, togglePassword };
}
