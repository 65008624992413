import css from './style.module.css';
import { SidebarItems } from './SidebarItems';
import { SidebarHeader } from './SidebarHeader';
import { useDashboardContext } from '@/layouts/dashboard/Provider';

const cls = {
  close: `hidden`,
  container: `pb-32 lg:pb-6`,
  open: `absolute w-8/12 z-40 sm:w-5/12`,
  default: `bg-white left-0 shadow h-screen overflow-y-auto top-0 lg:block lg:relative lg:w-64 lg:z-auto`,
};

export function Sidebar() {
  const { isSidebarOpen } = useDashboardContext();
  return (
    <aside
      className={`${cls.default}  
         ${isSidebarOpen ? cls.open : cls.close} ${css.scrollbar}
      `}
    >
      <div className={cls.container}>
        <SidebarHeader />
        <SidebarItems />
      </div>
    </aside>
  );
}
