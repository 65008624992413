import { api } from '@/services/config';
import { getStorage } from '@/utils/storage';
import { User, AccessRuleRessourceName, AccessRuleRessourceType } from '@/types/remote';

export function setAuthorizationHeader() {
  const storage = getStorage();
  const accessToken = storage.get.accessToken;

  if (storage.get.accessToken) {
    return (api.defaults.headers.common['Authorization'] = `${accessToken}`);
  }
}

export function hasPermission({
  can,
  authUser,
  ressource,
}: {
  authUser: User;
  ressource: AccessRuleRessourceName;
  can: AccessRuleRessourceType;
}): boolean {
  return authUser?.rules?.items?.some((rule) => rule.entity === ressource && rule.type === can);
}
