export function useGetErrorCode() {
  const errors = [
    {
      code: 'E00000',
      message: "Une erreur interne s'est produite",
    },
    {
      code: 'E00001',
      message: 'Ressource introuvable',
    },
    {
      code: 'E00002',
      message: 'Compte non confirmé',
    },
    {
      code: 'E00003',
      message: 'Compte suspendu',
    },
    {
      code: 'E00004',
      message: 'Identifiants invalides',
    },
    {
      code: 'E00005',
      message: 'Token invalide ou expiré',
    },
    {
      code: 'E00006',
      message: 'Compte déjà confirmé',
    },
    {
      code: 'E00007',
      message: 'Impossible de supprimer ce type car il contient certains projets',
    },
    {
      code: 'E00008',
      message:
        'Impossible de supprimer ces styles de musique car ils contiennent certaines musiques',
    },
    {
      code: 'E00009',
      message:
        'Impossible de supprimer ces sous-styles de musique car ils contiennent certaines musiques',
    },
    {
      code: 'E00010',
      message: 'Cette musique existe déjà dans la playlist',
    },
    {
      code: 'E00011',
      message: 'Non autorisé',
    },
    {
      code: 'E00012',
      message: 'Email non disponible',
    },
    {
      code: 'E00013',
      message: 'Clé FCM introuvable',
    },
    {
      code: 'E00014',
      message: 'Validation échouée, Panier introuvable',
    },
    {
      code: 'E00015',
      message: "L'adresse email ou le numéro de téléphone ne sont pas disponible pour le moment",
    },
    {
      code: 'E00016',
      message: 'Le mot actuel est incorrect',
    },
  ];

  return (code?: string) => {
    if (!code) {
      return errors[0];
    }
    return errors.find((item) => item.code === code);
  };
}
