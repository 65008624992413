import { TbUsers } from 'react-icons/tb';
import { GoReport, GoUnlink } from 'react-icons/go';
import { GiBlackFlag } from 'react-icons/gi';
import { FaMoneyBill } from 'react-icons/fa';
import { MdEmojiEvents } from 'react-icons/md';
import { AiOutlineProject } from 'react-icons/ai';
import { RiPlayList2Fill, RiUserStarFill } from 'react-icons/ri';

import { hasPermission } from '@/utils/authorization';
import { MusicIcon } from '@/components/icons/MusicIcon';
import { useFetchAuthUser } from '@/services/user/user.queries';
import { SiPrivateinternetaccess } from 'react-icons/si';

export function useSidebarData() {
  const { authUser } = useFetchAuthUser();
  return [
    {
      link: '/admin/users',
      section: 'Utilisateurs',
      icon: <TbUsers size="1.2rem" />,
      content: [],
      enabled: hasPermission({ can: 'read', ressource: 'user', authUser }),
    },
    {
      link: '/admin/artists',
      section: 'Artistes',
      icon: <RiUserStarFill size="1.2rem" />,
      content: [],
      enabled: hasPermission({ can: 'read', ressource: 'artist', authUser }),
    },
    {
      link: '/admin/contests',
      section: 'Concours',
      icon: <MdEmojiEvents size="1.3rem" />,
      content: [],
      enabled: hasPermission({ can: 'read', ressource: 'contest', authUser }),
    },
    {
      section: 'Playlists',
      link: '/admin/playlists',
      icon: <RiPlayList2Fill size="1.1rem" className="mt-0.5" />,
      content: [],
      enabled: hasPermission({ can: 'read', ressource: 'playlist', authUser }),
    },
    {
      section: "Liens d'affiliation",
      link: '/admin/invitations',
      icon: <GoUnlink size="1.1rem" className="mt-0.5" />,
      content: [],
      enabled: hasPermission({ can: 'read', ressource: 'affiliateLink', authUser }),
    },
    {
      section: 'Permissions',
      link: '/admin/permissions',
      icon: <SiPrivateinternetaccess size="1.1rem" className="mt-0.5" />,
      content: [],
      enabled: authUser?.roles?.includes('superadmin'),
    },
    {
      section: 'Projets',
      icon: <AiOutlineProject size="1.1rem" className="mt-0.5" />,
      content: [
        {
          title: 'Projets',
          link: '/admin/projects',
          enabled: hasPermission({ can: 'read', ressource: 'project', authUser }),
        },
        {
          title: 'Types des projets',
          link: '/admin/projects/types',
          enabled: hasPermission({ can: 'read', ressource: 'projectType', authUser }),
        },
      ],
      enabled:
        hasPermission({ can: 'read', ressource: 'project', authUser }) ||
        hasPermission({ can: 'read', ressource: 'projectType', authUser }),
    },
    {
      section: 'Musiques',
      icon: <MusicIcon className="size-5" />,
      content: [
        {
          title: 'Musiques',
          link: '/admin/music',
          enabled: hasPermission({ can: 'read', ressource: 'music', authUser }),
        },
        {
          title: 'Style de musique',
          link: '/admin/music/style',
          enabled: hasPermission({ can: 'read', ressource: 'styleMusic', authUser }),
        },
        {
          title: 'Sous style de musique',
          link: '/admin/music/sub-style',
          enabled: hasPermission({ can: 'read', ressource: 'subStyleMusic', authUser }),
        },
      ],
      enabled:
        hasPermission({ can: 'read', ressource: 'music', authUser }) ||
        hasPermission({ can: 'read', ressource: 'styleMusic', authUser }) ||
        hasPermission({ can: 'read', ressource: 'subStyleMusic', authUser }),
    },
    {
      section: 'Pays & Langues',
      icon: <GiBlackFlag size="1.2rem" className="mt-0.5" />,
      content: [
        {
          title: 'Pays',
          link: '/admin/countries',
          enabled: hasPermission({ can: 'read', ressource: 'country', authUser }),
        },
        {
          title: 'Langues',
          link: '/admin/languages',
          enabled: hasPermission({ can: 'read', ressource: 'language', authUser }),
        },
      ],
      enabled:
        hasPermission({ can: 'read', ressource: 'country', authUser }) ||
        hasPermission({ can: 'read', ressource: 'language', authUser }),
    },
    {
      section: 'Signalements',
      icon: <GoReport size="1.2rem" className="mt-0.5" />,
      content: [
        {
          title: 'Musiques',
          link: '/admin/report/music',
          enabled: hasPermission({ can: 'read', ressource: 'reportMusic', authUser }),
        },
        {
          title: 'Comptes',
          link: '/admin/report/users',
          enabled: hasPermission({ can: 'read', ressource: 'reportUser', authUser }),
        },
      ],
      enabled:
        hasPermission({ can: 'read', ressource: 'reportMusic', authUser }) ||
        hasPermission({ can: 'read', ressource: 'reportUser', authUser }),
    },
    {
      section: 'Données financières',
      link: '/admin/orders',
      icon: <FaMoneyBill size="1.3rem" className="" />,
      content: [],
      enabled: hasPermission({ can: 'read', ressource: 'order', authUser }),
    },
  ];
}
