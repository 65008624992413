import { api } from '@/services/config';

export async function signinPrivate(values: { email: string; password: string }) {
  const res = await api.post('/auth/private/signin', values);
  return res.data;
}

export async function passwordRecovery(body: { email: string }) {
  const res = await api.post('/auth/recover', body);
  return res.data;
}

export async function resendVerificationLink(body: { email: string }) {
  const res = await api.post('/auth/verify/resend', body);
  return res.data;
}
