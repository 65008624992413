import React from "react";
import __pages_import_0__ from "/src/pages/index.tsx";
const __pages_import_1__ = React.lazy(() => import("/src/pages/signout.tsx"));
const __pages_import_2__ = React.lazy(() => import("/src/pages/admin/artists.tsx"));
const __pages_import_3__ = React.lazy(() => import("/src/pages/admin/countries.tsx"));
const __pages_import_4__ = React.lazy(() => import("/src/pages/admin/invitations.tsx"));
const __pages_import_5__ = React.lazy(() => import("/src/pages/admin/languages.tsx"));
const __pages_import_6__ = React.lazy(() => import("/src/pages/admin/orders.tsx"));
const __pages_import_7__ = React.lazy(() => import("/src/pages/admin/permissions.tsx"));
const __pages_import_8__ = React.lazy(() => import("/src/pages/admin/playlists.tsx"));
const __pages_import_9__ = React.lazy(() => import("/src/pages/admin/users.tsx"));
const __pages_import_10__ = React.lazy(() => import("/src/pages/admin/contests/[contestId].tsx"));
const __pages_import_11__ = React.lazy(() => import("/src/pages/admin/contests/index.tsx"));
const __pages_import_12__ = React.lazy(() => import("/src/pages/admin/music/index.tsx"));
const __pages_import_13__ = React.lazy(() => import("/src/pages/admin/music/style.tsx"));
const __pages_import_14__ = React.lazy(() => import("/src/pages/admin/music/sub-style.tsx"));
const __pages_import_15__ = React.lazy(() => import("/src/pages/admin/projects/index.tsx"));
const __pages_import_16__ = React.lazy(() => import("/src/pages/admin/projects/types.tsx"));

const routes = [{"caseSensitive":false,"path":"/","element":React.createElement(__pages_import_0__)},{"caseSensitive":false,"path":"signout","element":React.createElement(__pages_import_1__)},{"caseSensitive":false,"path":"admin","children":[{"caseSensitive":false,"path":"artists","element":React.createElement(__pages_import_2__)},{"caseSensitive":false,"path":"countries","element":React.createElement(__pages_import_3__)},{"caseSensitive":false,"path":"invitations","element":React.createElement(__pages_import_4__)},{"caseSensitive":false,"path":"languages","element":React.createElement(__pages_import_5__)},{"caseSensitive":false,"path":"orders","element":React.createElement(__pages_import_6__)},{"caseSensitive":false,"path":"permissions","element":React.createElement(__pages_import_7__)},{"caseSensitive":false,"path":"playlists","element":React.createElement(__pages_import_8__)},{"caseSensitive":false,"path":"users","element":React.createElement(__pages_import_9__)},{"caseSensitive":false,"path":"contests","children":[{"caseSensitive":false,"path":":contestId","element":React.createElement(__pages_import_10__)},{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_11__)}]},{"caseSensitive":false,"path":"music","children":[{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_12__)},{"caseSensitive":false,"path":"style","element":React.createElement(__pages_import_13__)},{"caseSensitive":false,"path":"sub-style","element":React.createElement(__pages_import_14__)}]},{"caseSensitive":false,"path":"projects","children":[{"caseSensitive":false,"path":"","element":React.createElement(__pages_import_15__)},{"caseSensitive":false,"path":"types","element":React.createElement(__pages_import_16__)}]}]}];

export default routes;