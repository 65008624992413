import { useSidebarData } from './data';
import Collapse from './Collapse';
import { useRouterContainer } from '@/hooks/useRouterContainer';
import { RLink } from '@/components/_facade/RLink';

const cls = {
  active: `font-semibold mx-4 text-[13px] text-[#FFAD62]`,
  inactive: `font-light mx-4 text-[13px] text-gray-900`,
  link: `inline-flex items-center justify-start my-1 p-2 text-black`,
};

export function SidebarItems() {
  const { pathname } = useRouterContainer();
  const data = useSidebarData();
  return (
    <ul>
      <li>
        {data
          .filter((s) => s.enabled)
          .map((section) => (
            <div key={section.section}>
              {section.link && (
                <RLink
                  href={section.link}
                  className={`mb-10 flex justify-start px-4 text-sm  ${section.link === pathname ? 'text-primary' : 'text-black'}`}
                >
                  <span>{section.icon}</span>
                  <span className="pl-2 text-sm">{section.section}</span>
                </RLink>
              )}
              {!section?.link && (
                <div className="mb-8">
                  <Collapse>
                    <div className="flex">
                      <span>{section.icon}</span>
                      <span className="pl-2 text-sm">{section.section}</span>
                    </div>
                    {section.content
                      .filter((i) => i.enabled)
                      .map((item) => (
                        <div className="pl-5" key={item.title}>
                          <RLink href={item.link} className={cls.link}>
                            <span className={item.link === pathname ? cls.active : cls.inactive}>
                              {item.title}
                            </span>
                          </RLink>
                        </div>
                      ))}
                  </Collapse>
                </div>
              )}
            </div>
          ))}
      </li>
    </ul>
  );
}
