import { api } from '@/services/config';
import { AccessRule, UserRolesEnum } from '@/types/remote';
import { optionalProperty } from '@/utils/transformers/others';

export async function getAuthUser() {
  const res = await api.get('/users/current');
  return res.data;
}

export async function getAdminUsers() {
  const res = await api.get(`/users/private/admin`);
  return res.data;
}

export async function getUsersPrivate({
  page,
  limit,
  search,
  sortBy,
  status,
  sortOrder,
  countryId,
}: {
  page: number;
  limit: number;
  search?: string;
  sortBy?: string;
  countryId?: string;
  sortOrder?: 'asc' | 'desc';
  status: 'confirmed' | 'unconfirmed' | 'suspended';
}) {
  const res = await api.get(`/users/private`, {
    params: {
      page,
      limit,
      ...optionalProperty(!!search, { search }),
      ...optionalProperty(!!sortBy, { sortBy }),
      ...optionalProperty(!!sortOrder, { sortOrder }),
      ...optionalProperty(!!status, { status }),
      ...optionalProperty(!!countryId, { countryId }),
    },
  });
  return res.data;
}

// MUTATIONS

export async function createUserPrivate(args: {
  firstName: string;
  lastName: string;
  sceneName?: string;
  email: string;
  password: string;
  roles: UserRolesEnum[];
  cover?: string;
  coverWidth?: number;
  coverHeight?: number;
  countryId: string;
  phone: string;
  city?: string;
  street?: string;
  postalCode?: string;
}) {
  const res = await api.post('/users/private/create', args);
  return res.data;
}

export async function updateUserPrivate(args: {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  sceneName?: string;
  roles: UserRolesEnum[];
  cover?: string;
  coverWidth?: number;
  coverHeight?: number;
  countryId: string;
  phone: string;
  city?: string;
  street?: string;
  postalCode?: string;
}) {
  const res = await api.put('/users/private/update', args);
  return res.data;
}

export async function updateUser(args: {
  firstName: string;
  lastName: string;
  cover?: string;
  coverWidth?: number;
  coverHeight?: number;
  countryId: string;
  phone: string;
  city?: string;
  street?: string;
  postalCode?: string;
  bio?: string;
  sceneName?: string;
}) {
  const res = await api.put('/users/update', args);
  return res.data;
}

export async function deleteUser(args: { userId: string }) {
  const res = await api.put('/users/private/delete-permanent', args);
  return res.data;
}

export async function updateUserRules(values: {
  userId: string;
  redirectionLink: string | null;
  rules: Pick<AccessRule, 'id' | 'entity' | 'type' | 'position'>[];
}) {
  const result = await api.put('/users/private/update/rules', values);
  return result.data;
}
