import React from 'react';

export function useServerError() {
  const [isError, setIsError] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const set = (message: string | undefined) => {
    setIsError(true);
    if (message) {
      setMessage(message);
    } else {
      setMessage('une erreur inconnue est survenue');
    }
  };

  React.useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
        setMessage('');
      }, 4000);
    }
  }, [isError]);

  return { message, set };
}
