import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

interface Props extends React.ComponentProps<'button'> {
  block?: boolean;
  className?: string;
  color?: 'primary' | 'gray';
  disabled?: boolean;
  disabledCursor?: boolean;
  children: React.ReactNode;
  loading?: boolean;
  size?: 'sm' | 'lg' | 'xl';
}

const cls = {
  block: `flex justify-center w-full`,
  default: `transition ease-in duration-100 rounded text-sm`,
  disabled: `opacity-60`,
  colors: {
    primary: 'text-white active:opacity-70 bg-primary font-bold',
    gray: 'text-white active:opacity-70 bg-tertiary font-medium',
  },
  size: {
    sm: 'text-sm  py-2.5 px-5',
    lg: 'text-sm  py-3.5 px-8',
    xl: 'text-sm  py-2.5 px-5',
  },
};

export const Btn = React.forwardRef(
  (
    {
      color = 'primary',
      block = false,
      disabled = false,
      disabledCursor = true,
      children,
      className,
      loading = false,
      size = 'sm',
      type = 'button',
      ...rest
    }: Props,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    return (
      <button
        {...rest}
        type={type}
        ref={ref}
        disabled={disabled || loading}
        className={`${className} ${block ? cls.block : ''}
         ${cls.default} ${cls.colors[color]} ${cls.size[size]}
         ${disabled && disabledCursor ? 'cursor-not-allowed' : ''}
         ${disabled && cls.disabled}
      `}
      >
        {type === 'submit' ? (
          <span className="flex items-center gap-2">
            <span hidden={!loading}>
              <AiOutlineLoading3Quarters size="1.1rem" className="animate-spin" />
            </span>
            {children}
          </span>
        ) : (
          <>{children}</>
        )}
      </button>
    );
  },
);

Btn.displayName = 'Btn';
