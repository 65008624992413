import { SVGAttributes } from 'react';

interface IMusicIcon extends SVGAttributes<HTMLOrSVGElement> {
  size?: number;
}

function MusicIcon({ ...rest }: IMusicIcon) {
  return (
    <svg {...rest} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.332 24.936C29.2241 33.6822 18.8066 37.4399 10.0604 33.332C1.31412 29.2241 -2.44357 18.8066 1.66437 10.0604C5.7723 1.31412 16.1897 -2.44357 24.936 1.66437C33.6822 5.7723 37.4399 16.1897 33.332 24.936Z"
        fill="#2F2C26"
      />
      <path
        d="M22.8996 20.0374C21.4986 23.0195 17.946 24.3005 14.9589 22.8995C11.9768 21.4985 10.6908 17.9459 12.0968 14.9588C13.4978 11.9767 17.0554 10.6907 20.0375 12.0917C23.0196 13.4977 24.3006 17.0503 22.8996 20.0374Z"
        fill="#FEE449"
      />
      <path
        d="M18.4264 17.9359C18.1862 18.4513 17.5708 18.6714 17.0554 18.4312C16.54 18.1911 16.3199 17.5756 16.56 17.0603C16.8002 16.5449 17.4156 16.3247 17.931 16.5649C18.4464 16.8101 18.6715 17.4205 18.4264 17.9359Z"
        fill="#FA773F"
      />
      <path
        d="M14.7037 23.4448C14.1032 23.1646 13.5679 22.7993 13.0975 22.374L7.01318 30.75C8.0139 31.5406 9.10968 32.2311 10.3155 32.7965C11.5164 33.3619 12.7523 33.7672 13.9982 34.0323L16.555 24.0052C15.9246 23.9101 15.3041 23.73 14.7037 23.4448Z"
        fill="#BABABA"
      />
      <path
        d="M20.2927 11.5464C19.6922 11.2662 19.0718 11.0811 18.4464 10.991L21.0032 0.963867C22.2491 1.22906 23.485 1.63435 24.6858 2.19975C25.8867 2.76515 26.9875 3.45565 27.9882 4.24621L21.8988 12.6172C21.4285 12.1919 20.8931 11.8316 20.2927 11.5464Z"
        fill="#BABABA"
      />
    </svg>
  );
}

export { MusicIcon };
