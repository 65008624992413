import { useQuery } from '@tanstack/react-query';
import { User } from '@/types/remote';
import { getStorage } from '@/utils/storage';
import { UseQueryCommonOptions } from '@/types/local';
import { getAdminUsers, getAuthUser, getUsersPrivate } from '@/services/user/user.actions';

export function useFetchAuthUser() {
  const storage = getStorage();
  const status = storage.get.accessToken;
  const queryKey = ['auth-user', status];
  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: getAuthUser,
    enabled: !!status,
  });
  return { authUser: data?.user as User, isLoading, refetch, queryKey };
}

export function useFetchAdminUsers({ queryOptions }: { queryOptions?: UseQueryCommonOptions }) {
  const queryKey = ['admin-users'];
  const { data, isLoading } = useQuery({
    ...queryOptions,
    queryKey,
    queryFn: getAdminUsers,
  });
  return {
    isLoading,
    queryKey,
    users: data?.users as User[],
    count: data?.count as number,
    currentPage: data?.currentPage as number,
    totalPages: data?.totalPages as number,
    hasNextPage: data?.hasNextPage as boolean,
  };
}

export function useFetchUsersPrivate({
  page,
  limit,
  search,
  sortBy,
  status,
  sortOrder,
  countryId,
  queryOptions,
}: {
  page: number;
  limit: number;
  search?: string;
  sortBy?: string;
  sortOrder?: 'desc' | 'asc';
  countryId?: string;
  status: 'confirmed' | 'unconfirmed' | 'suspended';
  queryOptions?: UseQueryCommonOptions;
}) {
  const queryKey = ['users', page, limit, search, sortBy, sortOrder, status, countryId];
  const { data, isLoading } = useQuery({
    ...queryOptions,
    queryKey,
    queryFn: async () =>
      getUsersPrivate({ page, limit, search, sortOrder, sortBy, status, countryId }),
  });
  return {
    isLoading,
    queryKey,
    users: data?.users as User[],
    count: data?.count as number,
    currentPage: data?.currentPage as number,
    totalPages: data?.totalPages as number,
    hasNextPage: data?.hasNextPage as boolean,
  };
}
