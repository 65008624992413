import { CSSProperties } from '@mantine/core';

export const stylesObject: CSSProperties = {
  multiSelect: {
    root: {
      position: 'relative',
      marginBottom: '2rem', // Equivalent to mb-8 in Tailwind CSS
    },
    dropdown: {
      color: '#4b5563',
    },
    input: {
      height: 'auto',
      fontSize: '1rem', // Equivalent to text-base in Tailwind CSS
      paddingTop: '30px', // Equivalent to pt-6 in Tailwind CSS
      borderRadius: '0.5rem', // Equivalent to rounded-lg in Tailwind CSS
      color: '#4b5563', // Equivalent to text-gray-700 in Tailwind CSS
    },
    label: {
      fontWeight: 'bold',
      fontSize: '0.6875rem', // Equivalent to text-[11px] in Tailwind CSS
      color: '#9CA3AF', // Equivalent to text-gray-400 in Tailwind CSS
      position: 'absolute',
      pointerEvents: 'none',
      zIndex: '10',
      paddingTop: '0.5rem', // Equivalent to pt-2 in Tailwind CSS
      paddingLeft: '0.5rem', // Equivalent to pl-2 in Tailwind CSS
    },
  },
  popover: {
    dropdown: {
      backgroundColor: 'transparent',
      border: 'none',
      color: 'black',
      paddingLeft: 0,
      paddingRight: 5,
    },
  },
  field: {
    root: {
      position: 'relative',
      marginBottom: '2rem', // Equivalent to mb-8 in Tailwind CSS
    },
    input: {
      height: 'auto',
      fontSize: '1rem', // Equivalent to text-base in Tailwind CSS
      paddingTop: '18px', // Equivalent to pt-6 in Tailwind CSS
      borderRadius: '0.5rem', // Equivalent to rounded-lg in Tailwind CSS
      color: '#4b5563', // Equivalent to text-gray-700 in Tailwind CSS
    },
    label: {
      fontWeight: 'bold',
      fontSize: '0.6875rem', // Equivalent to text-[11px] in Tailwind CSS
      color: '#9CA3AF', // Equivalent to text-gray-400 in Tailwind CSS
      position: 'absolute',
      pointerEvents: 'none',
      zIndex: '10',
      paddingTop: '0.5rem', // Equivalent to pt-2 in Tailwind CSS
      paddingLeft: '0.5rem', // Equivalent to pl-2 in Tailwind CSS
    },
  },
  select: {
    root: {
      position: 'relative',
    },
    input: {
      height: 'auto',
      fontSize: '1rem',
      paddingTop: '1.5rem',
      borderRadius: '0.5rem',
      color: '#4b5563',
    },
    label: {
      fontWeight: 'bold',
      fontSize: '0.6875rem',
      color: '#9CA3AF',
      position: 'absolute',
      pointerEvents: 'none',
      zIndex: '10',
      paddingTop: '0.5rem',
      paddingLeft: '0.5rem',
    },
  },
  floating: {
    root: {
      position: 'relative',
      marginBottom: '2rem',
      borderBottom: '1px solid #e5e5e5', // Equivalent to border-b in Tailwind CSS
    },
    input: {
      height: 'auto',
      border: 'none',
      fontSize: '1rem',
      borderRadius: '0.5rem',
      color: 'white',
      backgroundColor: 'black',
    },
    placeholder: {
      color: 'white',
    },
    label: {
      fontWeight: 'bold',
      fontSize: '0.6875rem',
      color: '#9CA3AF',
      position: 'absolute',
      pointerEvents: 'none',
      zIndex: '10',
      paddingTop: '0.5rem',
      paddingLeft: '0.5rem',
      visibility: 'hidden', // Equivalent to hidden in Tailwind CSS
    },
  },
  fiedl2: {
    label: {
      fontWeight: 'bold',
      fontSize: '0.875rem', // Equivalent to text-sm in Tailwind CSS
      color: '#9CA3AF',
    },
  },
  field3: {
    root: {
      position: 'relative',
      marginBottom: '2rem',
    },
    input: {
      height: 'auto',
      paddingTop: '1.5rem',
      backgroundColor: '#E5E5E5',
      fontSize: '1rem',
      borderRadius: '0.5rem',
      color: '#4b5563',
    },
    label: {
      fontWeight: 'bold',
      fontSize: '0.6875rem',
      color: '#9CA3AF',
    },
  },
};

export const globalStyles = {
  field: {
    input: 'mobile:text-base',
    root: 'mb-6',
    label: 'text-gray-800',
  },
  select: {
    input: 'mobile:text-base',
    root: 'mb-6',
    label: 'text-grayer',
  },
  floating: {
    root: 'relative mb-8 border-b',
    input: 'h-auto border-0 text-base rounded-lg text-white bg-black',
    placeholder: 'text-white',
    label: `hidden font-bold text-[11px] text-gray-400 absolute pointer-events-none z-10 pt-2 pl-2`,
  },
  fiedl2: {
    label: `font-bold text-sm text-gray-400`,
  },
  field3: {
    root: 'relative mb-8',
    input: 'h-auto pt-6 bg-[#E5E5E5] text-base rounded-lg text-gray-700',
    label: `font-bold text-[11px] text-gray-400`,
  },
};

/*
const useStyles = createStyles((theme, { floating }: { floating: boolean }) => ({
  root: {
    position: 'relative',
    marginBottom: '4rem',
  },
  wrapper: {
    borderBottom: '1px solid white',
  },
  label: {
    position: 'absolute',
    zIndex: 2,
    top: 11,
    left: theme.spacing.sm,
    pointerEvents: 'none',
    color: floating ? theme.white : theme.white,
    transition: 'transform 150ms ease, color 150ms ease, font-size 150ms ease',
    transform: floating ? `translate(-${theme.spacing.sm}px, -28px)` : 'none',
    fontSize: floating ? theme.fontSizes.xs : theme.fontSizes.sm,
    fontWeight: floating ? 500 : 400,
  },
  required: {
    transition: 'opacity 150ms ease',
    opacity: floating ? 1 : 0,
  },
  input: {
    borderBottom: '1px solid white',
    backgroundColor: 'black',
    color: 'white',
    border: 'none',
  },
}));
*/
