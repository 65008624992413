type ErrorMessageProps = {
  text: string | undefined;
  className?: string;
};

export function ErrorMessage({ text, className = 'mb-5 text-center' }: ErrorMessageProps) {
  if (!text) {
    return null;
  }
  return <p className={`text-danger ${className}`}>{text}</p>;
}
