import {
  Outlet,
  useParams,
  useLocation,
  useNavigate,
  useSearchParams,
  ScrollRestoration,
} from 'react-router-dom';

export function useRouterContainer() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const router = {
    push: (path: string) => navigate(path),
    replace: (path: string) => navigate(path, { replace: true }),
    asPath: location.pathname,
    pathname: location.pathname,
    params,
  };
  const pathname = location.pathname;

  const updateSearchParams = () => {
    setSearchParams(searchParams);
  };
  return { router, pathname, searchParams, updateSearchParams };
}

export { Outlet, ScrollRestoration };

export function useRouteParams() {
  const params = useParams();
  return {
    contestId: params?.contestId as string,
  };
}
