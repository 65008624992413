import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

interface RLinkProps {
  children: React.ReactNode;
  href: string;
  className?: string;
  style?: CSSProperties;
}

export const RLink = React.forwardRef(
  ({ href, className, style, ...rest }: RLinkProps, ref: React.Ref<HTMLAnchorElement>) => {
    return <Link to={href} {...rest} ref={ref} className={className} style={style} />;
  },
);

RLink.displayName = 'RLink';
