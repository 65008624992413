import React from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { TextInput, TextInputProps } from '@mantine/core';

import { colors } from '@/utils/colors';
import { globalStyles } from '@/utils/globalStyles';
import { useTogglePassword } from '@/hooks/useTogglePassword';

export const RPasswordInput = React.forwardRef(
  (props: TextInputProps, ref: React.Ref<HTMLInputElement>) => {
    const { togglePassword, type } = useTogglePassword();
    return (
      <TextInput
        {...props}
        ref={ref}
        type={type}
        classNames={{ ...globalStyles.field }}
        rightSection={
          type === 'password' ? (
            <button onClick={togglePassword}>
              <FaEye size="1.2rem" color={props.error ? colors.danger : colors.black} />
            </button>
          ) : (
            <button type="button" onClick={togglePassword}>
              <FaEyeSlash size="1.2rem" color={props.error ? colors.danger : colors.black} />
            </button>
          )
        }
      />
    );
  },
);

RPasswordInput.displayName = 'RPasswordInput';
