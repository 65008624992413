import React from 'react';

import { api } from '@/services/config';
import { useForm } from '@/hooks/useForm';
import { Btn } from '@/components/ui/Btn';
import css from '@/styles/login.module.css';
import { getStorage } from '@/utils/storage';
import { RLink } from '@/components/_facade/RLink';
import { MainText } from '@/components/base/MainText';
import { useServerError } from '@/hooks/useServerError';
import { useGetErrorCode } from '@/hooks/useGetErrorCode';
import { RTextInput } from '@/components/_facade/RTextInput';
import { ErrorMessage } from '@/components/base/ErrorMessage';
import { useRouterContainer } from '@/hooks/useRouterContainer';
import { RPasswordInput } from '@/components/_facade/RPasswordInput';
import { useSigninPrivateMutation } from '@/services/auth/auth-mutations';
import { jwtDecode } from 'jwt-decode';

type FormValues = {
  email: string;
  password: string;
};

const cls = {
  container: 'md:flex text-black md:justify-center h-screen',
  width: 'mt-32 md:w-10/12 lg:w-7/12 2xl:w-5/12',
  content: 'py-12 mb-12 px-2 md:px-8 rounded-2xl bg-white',
  errorMessage: 'font-bold text-center text-danger mb-4',
  forgetPasswordContainer: 'text-primary mb-8 font-medium text-lg',
  submitBtn: 'rounded-2xl py-3 px-4 font-extrabold text-2xl',
};

export default function HomePage() {
  const storage = getStorage();
  const errorCode = useGetErrorCode();
  const { router } = useRouterContainer();
  const serverError = useServerError();
  const mutation = useSigninPrivateMutation();

  const { handleSubmit, register, formState } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { errors, isSubmitting } = formState;

  const onSubmit = (formValues: FormValues) => {
    mutation.mutate(formValues, {
      onSuccess: (data: { accessToken: string; redirectionLink: string }) => {
        const accessToken = data.accessToken;
        api.defaults.headers.common['Authorization'] = accessToken;
        storage.set.accessToken(accessToken);
        window.location.href = data.redirectionLink || '/admin/users';
      },
      onError: (e: any) => {
        if (e.code === 404) {
          serverError.set('Ressource introuvable');
          return;
        }
        const error = errorCode(e.response.data.code);
        serverError.set(error?.message);
      },
    });
  };

  React.useEffect(() => {
    if (storage.get.accessToken) {
      const decodedToken = jwtDecode(storage.get.accessToken) as any;
      router.push(decodedToken?.redirectionLink || '/admin/users');
    }
  }, [router, storage.get.accessToken]);

  return (
    <>
      <div>
        <div className={css.background}>
          <div className="container px-4 lg:mx-auto xl:px-16 2xl:px-44 3xl:px-64">
            <header className="w-full pt-5 md:pt-10">
              <RLink href="/" className={css.logo}>
                <img src="/images/logo-white.png" height={46} width={162} alt="Musicalee" />
              </RLink>
            </header>
            <main>
              <div className={cls.container}>
                <div className={cls.width}>
                  <div className={cls.content}>
                    <MainText center className="mb-10">
                      Bienvenue
                    </MainText>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <ErrorMessage text={serverError.message} className={cls.errorMessage} />
                      <RTextInput
                        label="Email"
                        required={true}
                        {...register('email')}
                        error={errors.email?.message}
                      />
                      <RPasswordInput
                        required={true}
                        label="Mot de passe"
                        {...register('password')}
                        error={errors.password?.message}
                      />
                      <div>
                        <Btn
                          loading={isSubmitting || mutation.isPending}
                          disabled={isSubmitting || mutation.isPending}
                          type="submit"
                          block={true}
                        >
                          Connexion
                        </Btn>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
