import React from 'react';

const cls = {
  text: 'font-extrabold text-2xl md:text-3xl xl:text-4xl',
  dot: 'text-primary',
};

export function MainText({
  children,
  className,
  center,
}: {
  className?: string;
  center?: boolean;
  children: React.ReactNode;
}) {
  return (
    <h1 className={`${cls.text} ${className} ${center && 'text-center'}`}>
      {children}
      <span className={cls.dot}>.</span>
    </h1>
  );
}
