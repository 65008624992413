function storageKey({ key, defaultValue }: { defaultValue: string; key: string }) {
  if (typeof window !== 'undefined') {
    const item = localStorage.getItem(key);

    if (!item) {
      return defaultValue;
    }
    return item;
  }
}

const keys = {
  accessToken: 'musicalee-access-key',
};

export function getStorage() {
  const accessToken = storageKey({ key: keys.accessToken, defaultValue: '' });

  return {
    get: {
      accessToken,
    },
    set: {
      accessToken: (value: string) => localStorage.setItem(keys.accessToken, value),
    },
    remove: {
      accessToken: () => localStorage.removeItem(keys.accessToken),
    },
  };
}
