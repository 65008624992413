export function optionalProperty(condition: boolean, options: any) {
  if (condition) {
    return { ...options };
  }
}

export function getAudioDuration(src: string) {
  return new Promise((resolve, reject) => {
    const audio = new Audio(src);
    audio.onloadeddata = () => resolve(audio.duration);
    audio.onerror = reject;
  });
}

export function generateRandomToken(length: number) {
  //edit the token allowed characters
  const a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split('');
  const b = [];
  for (let i = 0; i < length; i++) {
    const j = (Math.random() * (a.length - 1)).toFixed(0) as any;
    b[i] = a[j];
  }
  return b.join('');
}

/*
export function getImageDimensions(src: string) {
  if (!src) {
    return new Promise((resolve, reject) => {
      resolve({
        coverWidth: undefined as undefined | number,
        coverHeight: undefined as undefined | number,
      });
    });
  }

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () =>
      resolve({
        coverWidth: img.width as number,
        coverHeight: img.height as number,
      });
    img.onerror = reject;
    img.src = src;
  });
}
*/
