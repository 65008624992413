import React from 'react';
import { useRouterContainer } from '@/hooks/useRouterContainer';

type DashboardProviderProps = {
  children: React.ReactNode;
};

type ProviderValues = {
  isSidebarOpen?: boolean;
  openSidebar?: () => void;
  closeSidebar?: () => void;
};

const Context = React.createContext<ProviderValues>({});

export function DashboardProvider({ children }: DashboardProviderProps) {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const { pathname } = useRouterContainer();

  const openSidebar = React.useCallback(() => {
    setIsSidebarOpen(true);
  }, []);

  const closeSidebar = React.useCallback(() => {
    setIsSidebarOpen(false);
  }, []);

  React.useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
  }, []);

  React.useEffect(() => {
    return () => {
      if (isSidebarOpen && window.innerWidth < 1024) {
        setIsSidebarOpen(false);
      }
    };
  }, [pathname, isSidebarOpen]);

  return (
    <Context.Provider value={{ isSidebarOpen, openSidebar, closeSidebar }}>
      {children}
    </Context.Provider>
  );
}

export function useDashboardContext() {
  return React.useContext(Context);
}
