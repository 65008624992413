import { AiOutlinePoweroff } from 'react-icons/ai';
import { RLink } from '@/components/_facade/RLink';
import { useDashboardContext } from '@/layouts/dashboard/Provider';

export function TopBar() {
  const { openSidebar } = useDashboardContext();
  return (
    <header className="relative z-10 h-16 items-center bg-white shadow md:h-20">
      <div className="relative z-10 mx-auto flex h-full flex-col justify-center px-3 text-white">
        <div className="relative flex w-full items-center pl-1 sm:ml-0 sm:pr-2">
          <div className="group relative flex h-full w-12 items-center">
            <button
              type="button"
              aria-expanded="false"
              aria-label="Toggle sidenav"
              onClick={openSidebar}
              className="text-4xl text-gray-700 focus:outline-none lg:hidden"
            >
              &#8801;
            </button>
          </div>
          <div className="relative ml-5 mr-0 flex w-full items-center justify-end p-1 text-gray-700 sm:right-auto sm:mr-0">
            <RLink href={'/signout'}>
              <button className="relative block pr-5">
                <AiOutlinePoweroff size="1.4rem" />
              </button>
            </RLink>

            <a href="#" className="relative block">
              <img
                alt="Stone Cold Steve Austin"
                src="/images/1.jpg"
                className="size-10  rounded-full object-cover"
              />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
