import React from 'react';
import { getStorage } from '@/utils/storage';
import { TopBar } from '@/layouts/dashboard/TopBar';
import { Overlay } from '@/layouts/dashboard/Overlay';
import { Sidebar } from '@/layouts/dashboard/Sidebar/Sidebar';
import { useRouterContainer } from '@/hooks/useRouterContainer';
import { useFetchAuthUser } from '@/services/user/user.queries';
import { DashboardProvider } from '@/layouts/dashboard/Provider';

const cls = {
  container: `bg-gray-100 text-black h-screen overflow-hidden relative`,
  mainContainer: `flex flex-col h-screen pl-0 w-full lg:space-y-4 lg:w-[calc(100%-16rem)]`,
  main: `h-screen overflow-auto pb-36 pt-8 px-2 md:pb-8 md:pt-4 md:px-8 lg:pt-0`,
};

function Layout({ children }: { children: React.ReactNode }) {
  const { authUser } = useFetchAuthUser();
  const { router } = useRouterContainer();

  React.useEffect(() => {
    const storage = getStorage();
    if (!storage.get.accessToken) {
      router.push('/');
    }
  }, [router]);

  return (
    <DashboardProvider>
      {!authUser && (
        <div className="grid h-screen place-items-center">
          <img src="/images/loading.gif" alt="" />
        </div>
      )}

      {authUser && (
        <div className={cls.container}>
          <Overlay />
          <div className="flex items-start">
            <Sidebar />
            <div className={cls.mainContainer}>
              <TopBar />
              <main className={cls.main}>{children}</main>
            </div>
          </div>
        </div>
      )}
    </DashboardProvider>
  );
}

const exceptions = ['/', '/signout'];

export function DashboardLayout({ children }: { children: React.ReactNode }) {
  const { pathname } = useRouterContainer();

  if (exceptions.includes(pathname)) {
    return <>{children}</>;
  }
  return <Layout>{children}</Layout>;
}
